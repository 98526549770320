body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



.nav-active {
  background-color: #F6F8FA;
  border-left: 10px solid rgba(45, 44, 44, 0.74);
  
  .show-inactive {
    opacity: 1;
    visibility: none;
    display: none;
  }
  .show-active {
    opacity: 1;
    visibility: visible;
  }
}
.nav-inactive{
  .show-active {
    opacity: 1;
    visibility: none;
    display: none;
  }
  .show-inactive {
    opacity: 1;
    visibility: visible;
  } 
}

.nav-text{
  color: #D7B569;
  text-decoration: none;
  
}

.page-body{
  height: 100vh;
  background-color: #ffffff;
}
/* Tab Container */
.Tabs {
  width: 100%;
  height: auto;
  /* height: 93%; */
  min-height: 400px;
  background: #FFFFFF;
  /* margin: 3.5rem auto 1.5rem; */
  padding: 2rem .3rem;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-start;

}
 
.react-tabs {
  -webkit-tap-highlight-color: transparent;

}
 
.react-tabs__tab-list {
  width: 70%;
  /* margin: 0 auto 2rem; */
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding-left: 0px;
  margin-bottom: 1.5rem;
}
 
.react-tabs__tab {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
 
.react-tabs__tab--selected {
  border-bottom: 6px solid #D7B569 !important;
}
 
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
 
.react-tabs__tab:hover {
  background: rgba(50, 224, 196, 0.15);
}
 
.react-tabs__tab-panel {
  display: none;
}
 
.react-tabs__tab-panel--selected {
  display: block;
  /* font-size: 2rem; */
  text-align: center;
}

.react-tabs__tab:hover{
  background-color: white !important;
}
/* 
.MuiTableCell-root {
  border: 2px solid green;
  margin: 10px;
} */
.nav-text {
  color: #D7B569;
  text-decoration: none;

}

.page-body {
  height: 100vh;
  background-color: #ffffff;
}

input[type="checkbox"]{
  width: 25px;
  height: 25px;
  clip-path: circle(46% at 50% 50%);
}

.output-container {
  position: relative;
  width: 100%;
}
.output-container-amount {
  position: relative;
  width: 100%;
}


.watermark-ribbon {
  position: absolute;
  top: 0px;
  right: 0px;
 
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
}

.watermark-amount {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: rgba(53, 70, 32, 0.5);
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
}

.output {
  /* Add your styles for the output content here */
}


